if (
	location.hostname === "platinumfmdashboard.azurewebsites.net" ||
	location.hostname === "reporting.platinumfm.com.au"
) {
	window.location.href = "https://report-7eleven.platinumfm.com.au";
}

function getScrollbarWidth() {
	var outer = document.createElement("div");
	outer.style.visibility = "hidden";
	outer.style.width = "100px";
	outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

	document.body.appendChild(outer);

	var widthNoScroll = outer.offsetWidth;
	// force scrollbars
	outer.style.overflow = "scroll";

	// add innerdiv
	var inner = document.createElement("div");
	inner.style.width = "100%";
	outer.appendChild(inner);

	var widthWithScroll = inner.offsetWidth;

	// remove divs
	outer.parentNode.removeChild(outer);

	return widthNoScroll - widthWithScroll;
}

function addPaddingForScroll() {
	var previous = document.getElementById("tablesFix");
	if (previous) {
		previous.parentNode.removeChild(previous);
	}

	var width = getScrollbarWidth();
	var css =
		".scroll-vertical .datatable-header, .scroll-vertical .datatable-footer, .flexible-scroll .datatable-header, .flexible-scroll .datatable-footer, .scroll-vertical ~ .datatable-footer-custom { padding-right: " +
		width +
		"px; }",
		head = document.head || document.getElementsByTagName("head")[0],
		style = document.createElement("style");
	style.setAttribute("id", "tablesFix");

	style.type = "text/css";
	if (style.styleSheet) {
		// This is required for IE8 and below.
		style.styleSheet.cssText = css;
	} else {
		style.appendChild(document.createTextNode(css));
	}

	head.appendChild(style);
}

function initPaddingForScroll() {
	if (window.navigator.userAgent.indexOf("Edge") < 0) {
		addPaddingForScroll();
		var resizeTimer;
		window.addEventListener("resize", function () {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(function () {
				addPaddingForScroll();
			}, 250);
		});
	}
}

initPaddingForScroll();

function loadScript(scriptUrl) {
	//console.log("loading script " + scriptUrl);

	// load theme script
	var script = document.createElement("script");
	script.src = scriptUrl;
	script.type = "text/javascript";
	document.body.appendChild(script);

	// load theme css
	var link = document.createElement("link");
	link.rel = "stylesheet";
	link.href = scriptUrl.replace("js", "css");
	var head = document.getElementsByTagName("head")[0];
	head.appendChild(link);
}

if (location.hostname.includes("-barwonwater")) {
	loadScript("barwonwater.js");
} else if (
	location.hostname.includes("-demonstration") ||
	location.hostname.includes("-informdemo")
) {
	loadScript("demonstration.js");
} else if (
	location.hostname.includes("-cityofwhittlesea") ||
	location.hostname.includes("-visionaustralia")
) {
	loadScript("whittlesea.js");
} else if (location.hostname.includes("-sgh")) {
	loadScript("sgh.js");
} else if (location.hostname.includes("-racv")) {
	loadScript("racv.js");
} else if (location.hostname.includes("reporting.property")) {
	loadScript("racv.js");
} else if (location.hostname.includes("-mecca")) {
	loadScript("mecca.js");
} else {
	loadScript("7eleven.js");
}
